<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          ref="refreshCard"
          class="cardActions"
          action-refresh
          @refresh="refreshStop('refreshCard')"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block"> {{
                  baslangic
                      ? baslangic + ' ile ' + bitis + ' tarih aralığındaki kayıtlar'
                      : 'Online Satış Listesi'
                }}</h4>
              <b-button
                  v-b-modal.hizli-fatura-olustur
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-1 border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                />
                Fatura Oluştur
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  style="margin-right: 10px!important;"
                  class="myButton border-0 float-right"
                  @click="downloadExcel()"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <b-spinner v-if="excelLoading" small/>
                <feather-icon
                    v-else
                    icon="FileTextIcon"
                    class="mr-50"
                />
                Excel Çıktısını Al
              </b-button>
            </b-col>
          </div>
        </b-row>
        <hr class="mt-4 mb-0">
        <b-row>
          <b-col cols="12">
            <b-input-group class="float-right justify-content-end mt-1"
                           :style="width < 600 ? {display : 'grid',justifyContent:'center!important'}:''"
            >
              <b-input-group-prepend>
                <flat-pickr
                    v-model="baslangic"
                    class="form-control"
                />
              </b-input-group-prepend>
              <b-button
                  variant="outline-primary"
                  disabled
                  class="disabledColor"
              >
                <feather-icon icon="CalendarIcon"/>
              </b-button>
              <b-input-group-append>
                <flat-pickr
                    v-model="bitis"
                    class="form-control"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
              :class="width < 600 ? 'col-12':''"
              class="my-1 col-6"
          >
            <b-input-group>
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
              >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                    @change="perPageChange($event)"
                />
              </b-input-group>
            </b-input-group>
          </b-col>
          <b-col
              :class="width < 600 ? ['col-12','my-25']:''"
              :style="{justifyContent  : width< 600 ? 'start!important':''}"
              class="my-1 d-flex justify-content-end col-6"
          >
            <b-input-group
                size="sm"
                class="searchGroup"
                :style="{width : width < 750 ? '100%!important' : width < 700 ? '50%!important' : width > 1000 ? '30%!important':''}"
            >
              <b-input-group-prepend>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="araButton"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-spinner
            v-if="secondIslem"
            variant="primary"
            class="spinners"
        />
        <b-table
            hover
            striped
            empty-text=""
            :items="salaryListItems"
            :fields="fields"
            :per-page="perPage"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
        >
          <template #cell(kayitTarih)="row">
            <b-badge
                pill
                variant="light-danger"
            >{{
                row.item['kayitTarih']
              }}
            </b-badge>
          </template>
          <template #cell(siparisNo)="row">
            <b-badge
                pill
                variant="bgPrimary"
            >
              {{ row.item['token'] }}
            </b-badge>
          </template>
          <template #cell(aMount)="row">
            <p style="font-weight: bold">
              {{ row.item['aMount'] }} TL
            </p>
          </template>
          <template #cell(girisTarih)="row">
            <p style="font-weight: bold">
              {{ row.item['girisTarih'] + ' / ' + row.item['cikisTarih'] }}
            </p>
          </template>
          <template #cell(ccName)="row">
            <p style="font-weight: bold; color: #9367ec">
              {{ row.item['ccName'] }}
            </p>
          </template>
          <template #cell(faturaNo)="row">
            <p style="font-weight: bold; color: #00cfff">
              {{ row.item['faturaNo'] }}
            </p>
          </template>
          <template #cell(actions)="row">
            <b-button
                v-if="!row.item['faturaNo']"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.fatura-create
                variant="success"
                size="sm"
                class="mr-1"
                @click="saveEvident(row.item, row.index, $event.target)"
                :style="{backgroundColor:$store.getters.bgSuccess}"
            >
              <feather-icon
                  icon="HomeIcon"
                  class="mr-50"
              />
              <span class="align-middle">Fatura Oluştur</span>
            </b-button>
            <b-button
                v-else
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="mr-1"
                @click="faturaShow(row)"
            >
              <feather-icon
                  icon="HomeIcon"
                  class="mr-50"
              />
              <span class="align-middle">Faturayı Görüntüle</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="mr-1"
                :to="'/siparisDetay/' + row.item['siparisNo']"
                @click="row.toggleDetails"
            >
              <feather-icon
                  icon="HomeIcon"
                  class="mr-50"
              />
              <span class="align-middle">Konuklar</span>
            </b-button>
          </template>
        </b-table>
        <hr>
        <b-row class="mt-3 alignItemsCenter">
          <b-col cols="6">
            <p class="mt-2 text-muted">
              {{ toplamKayit }} Kayıttan 1 - {{ perPage }} arasındaki kayıtlar
              gösteriliyor
            </p>
          </b-col>
          <div class="demo-spacing-0">
            <b-col
                cols="6"
                class=""
            >
              <b-pagination
                  v-model="currentPage"
                  :align="'rigth'"
                  :per-page="perPage"
                  :total-rows="totalRows"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="currentPageChange($event)"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </div>
        </b-row>
      </b-card-actions>
    </div>
    <b-modal
        id="fatura-create"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        header-bg-variant="white"
        hide-footer
        @hide="faturaModalHide"
    >
      <b-row class="p-3">
        <b-col
            cols="12"
            class="text-center"
        >
          <h1>Fatura Oluştur</h1>
        </b-col>
        <b-col
            cols="12"
            class="text-center"
        >
          <p>
            *TC Kimlik numarası 11 haneli, Vergi Kimlik Numarası 10 haneli
            olmalıdır.
          </p>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Fatura Ünvanı *Bireysel için isim soyisim"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoUnvan"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Faturanın Gideceği E-Posta"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoMail"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="İsim * E-Arşiv için zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoIsim"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Soy İsim* E-Arşiv için zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoSoyIsim"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Vergi Dairesi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoDaire"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Vergi Numarasi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoNumara"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Fatura Adresi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoAdres"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Fatura İl"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoIl"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Fatura İlce"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoIlce"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Telefon"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="invoTelefon"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-button
            v-if="wait"
            variant="success"
            disabled
            class="mr-1"
            :style="{backgroundColor:$store.getters.bgSuccess}"
        >
          <b-spinner small/>
          Bekleyiniz...
        </b-button>
        <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="mr-12 border-0"
            :style="{backgroundColor:$store.getters.bgSuccess}"
            @click="faturaCreated"
        >
          Fatura Oluştur
        </b-button>
        <b-button
            v-show="!wait"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="bgDanger"
            class="border-0"
            :style="{backgroundColor:$store.getters.bgDanger}"
            @click="faturaModalHide"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'
import XLSX from '@/assets/xlsx/xlsx.full.min'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BCardActions,
    BInputGroupPrepend,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      excelLoading: false,
      spinner: true,
      toplamKayit: this.$store.state.raporlar.posFatura.toplamKayit,
      wait: false,
      islem: false,
      secondIslem: false,
      is: '',
      baslangic: '',
      bitis: '',
      fields: [
        {
          key: 'kayitTarih',
          label: 'İŞLEM TARİHİ',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'siparisNo',
          label: 'SİPARİŞ NO',
          class: 'text-center',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'aMount',
          label: 'FİYAT',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'girisTarih',
          label: 'GİRİŞ TARİHİ / ÇIKIŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'ccName',
          label: 'KART SAHİBİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'faturaNo',
          label: 'FATURA NO',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      choose: [],
      unvan: '',
      mail: '',
      isim: '',
      soyIsim: '',
      daire: '',
      numara: '',
      adresi: '',
      il: '',
      ilce: '',
      telefon: '',
      invoUnvan: '',
      invoMail: '',
      invoIsim: '',
      invoSoyIsim: '',
      invoDaire: '',
      invoNumara: '',
      invoAdres: '',
      invoIl: '',
      invoIlce: '',
      invoTelefon: '',
      invoSiparisNo: '',
      invoToplamTutar: '',
    }
  },
  computed: {
    salaryListItems() {
      const myObj = this.$store.getters.posFaturaReturn?.kayitlar
      return myObj
    },
  },
  watch: {
    baslangic(newVal) {
      this.currentPage = 1
      this.islem = true
      const veri = newVal * this.perPage - this.perPage
      this.$refs.refreshCard.showLoading = true
      const today = new Date()
      const yil = today.getFullYear()
      const gun = String(today.getDate())
          .padStart(2, '0')
      const ay = String(today.getMonth() + 1)
          .padStart(2, '0')
      const mevcutGun = `${yil}-${ay}-${gun}`
      this.$store
          .dispatch('pos&FaturaFiltre', {
            start: newVal,
            end: this.bitis || mevcutGun,
            uzunluk: this.perPage,
            baslangic: veri,
            searchKey: this.filter,
            filtre: true,
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
            this.toplamKayit = this.$store.getters.posFaturaReturn.toplamKayit
            this.totalRows = this.$store.getters.posFaturaReturn.toplamKayit
            // this.totalRows = Object.keys(res.liste).length
          })
    },
    bitis(newVal) {
      this.currentPage = 1
      this.islem = true
      const veri = newVal * this.perPage - this.perPage
      this.$refs.refreshCard.showLoading = true
      const today = new Date()
      const yil = today.getFullYear()
      const gun = String(today.getDate())
          .padStart(2, '0')
      const ay = String(today.getMonth() + 1)
          .padStart(2, '0')
      const mevcutGun = `${yil}-${ay}-${gun}`
      this.$store
          .dispatch('pos&FaturaFiltre', {
            start: this.baslangic || mevcutGun,
            end: newVal,
            uzunluk: this.perPage,
            baslangic: veri,
            searchKey: this.filter,
            filtre: true,
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
            this.toplamKayit = this.$store.getters.posFaturaReturn.toplamKayit
            this.totalRows = this.$store.getters.posFaturaReturn.toplamKayit
            // this.totalRows = Object.keys(res.liste).length
          })
    },
    filter(newVal) {
      if (this.baslangic && this.bitis) {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('pos&FaturaFiltre', {
              uzunluk: this.perPage,
              baslangic: 0,
              searchKey: newVal,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.toplamKayit = this.$store.getters.posFaturaReturn.toplamKayit
              this.totalRows = this.$store.getters.posFaturaReturn.toplamKayit
            })
      }
      this.islem = true
      this.$refs.refreshCard.showLoading = true
      this.$store
          .dispatch('pos&FaturaFiltre', {
            uzunluk: this.perPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
            filtre: false,
          })
          .then(res => {
            this.islem = false
            this.$refs.refreshCard.showLoading = false
            this.toplamKayit = this.$store.getters.posFaturaReturn.toplamKayit
            this.totalRows = this.$store.getters.posFaturaReturn.toplamKayit
            // this.totalRows = Object.keys(res.liste).length
          })
    },
  },
  created() {
    this.islem = true
    this.secondIslem = true
    this.$store
        .dispatch('pos&Fatura', {
          uzunluk: this.perPage,
          baslangic: 0,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          if (res.liste) {
            this.islem = false
            this.secondIslem = false
            this.toplamKayit = this.$store.getters.posFaturaReturn.toplamKayit
            this.totalRows = this.$store.getters.posFaturaReturn.toplamKayit
          }
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearPosFatura')
  },
  methods: {
    downloadExcel() {
      const self = this
      this.excelLoading = true
      const data = {
        crm_token: localStorage.getItem('otelUserToken'),
        serviceName: 'rapor',
        methodName: 'sepetKayitlariExcel',
        [this.baslangic ? 'start' : '']: this.baslangic,
        [this.baslangic ? 'end' : '']: this.bitis,
        [this.baslangic ? 'filtre' : '']: this.baslangic ? true : false,
      }
      $.ajax({
        type: 'POST',
        url: this.$store.state.POST_URL,
        data,
        success(res) {
          self.excelLoading = false
          if (res.liste.data) {
            const blob = new Blob([self.base64ToBlob(res.liste.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = self.baslangic && self.bitis ? `${self.baslangic}-${self.bitis} Konaklama L.` : 'Konaklama Listesi'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: 'Sonuç Bulunamadı',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        },
        error(err) {
          self.excelLoading = false
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: 'Hata Oluştu',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        },
        dataType: 'json',
      })
    },
    base64ToBlob(base64, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(base64)
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    currentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('pos&FaturaFiltre', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      } else {
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('pos&Fatura', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      }
    },
    perPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('pos&FaturaFiltre', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      } else {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('pos&Fatura', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      }
    },
    refreshStop(cardName) {
      (this.perPage = 15),
          (this.filter = ''),
          (this.baslangic = ''),
          (this.bitis = ''),
          this.$store
              .dispatch('pos&Fatura', {
                uzunluk: this.perPage,
                baslangic: 0,
              })
              .then(res => {
                this.islem = false
                this.$refs[cardName].showLoading = false
                this.secondIslem = false
                this.currentPage = 1
                this.perPage = 15
                this.toplamKayit = this.$store.getters.posFaturaReturn.toplamKayit
                this.totalRows = this.$store.getters.posFaturaReturn.toplamKayit
              })
    },
    faturaCreated() {
      const veri = this.currentPage * this.perPage - this.perPage
      this.wait = true
      this.$store
          .dispatch('faturaCreate', {
            unvan: this.invoUnvan,
            mail: this.invoMail,
            adi: this.invoIsim,
            soyAdi: this.invoSoyIsim,
            daire: this.invoDaire,
            numara: this.invoNumara.replace(/\s/g, ''),
            adres: this.invoAdres,
            il: this.invoIl,
            ilce: this.invoIlce,
            telefon: this.invoTelefon,
            siparisNo: this.invoSiparisNo,
            tutar: this.invoToplamTutar,
            konaklamaVergisi: 1,
            searchKey: this.filter,
            baslangic: veri,
            uzunluk: this.perPage,
            [this.baslangic ? 'start' : '']: this.baslangic,
            [this.bitis ? 'end' : '']: this.bitis,
            [this.baslangic || this.bitis ? 'filtre' : '']: true,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              const veri = this.currentPage * this.perPage - this.perPage
              this.$store.dispatch('pos&Fatura', {
                uzunluk: this.perPage,
                baslangic: veri,
              })
              this.$bvModal.hide('fatura-create')
              this.$swal({
                title: 'Başarılı',
                text: this.$store.getters.notificationSettings(res) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                icon: 'success',
                confirmButtonText: 'Kapat',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$swal({
                title: 'Hata!',
                text: this.$store.getters.notificationSettings(res) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                icon: 'error',
                confirmButtonText: 'Kapat',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(err => {
            this.$swal({
              title: 'Hata!',
              text: this.$store.getters.notificationSettings(err) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
              icon: 'error',
              confirmButtonText: 'Kapat',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .finally(() => {
            setTimeout(() => {
              this.wait = false
            }, 3000)
          })
    },
    saveEvident(veri) {
      this.invoUnvan = veri.faturaUnvan
      this.invoMail = veri.ePosta
      this.invoNumara = veri.vergiNumarasi
      this.invoAdres = veri.faturaAdres
      this.invoIl = veri.faturaIl
      this.invoIlce = veri.faturaIlce
      this.invoTelefon = veri.telefon
      this.invoSiparisNo = veri.siparisNo
      this.invoToplamTutar = veri.aMount
    },
    faturaShow(veri) {
      this.$store
          .dispatch('faturaDetay', { id: veri.item.faturaID })
          .then(res => {
            const myWindow = window.open(
                'about:blank',
                'print_popup',
                'width=1000,height=800',
            )
            myWindow.document.write(res)
            myWindow.print()
          })
          .catch(err => {
          })
    },
    faturaModalHide() {
      (this.invoUnvan = ''),
          (this.invoMail = ''),
          (this.invoIsim = ''),
          (this.invoSoyIsim = ''),
          (this.invoDaire = ''),
          (this.invoNumara = ''),
          (this.invoAdres = ''),
          (this.invoIl = ''),
          (this.invoIlce = ''),
          (this.invoTelefon = ''),
          (this.invoSiparisNo = ''),
          (this.invoToplamTutar = ''),
          (this.choose = [])
      this.mail = ''
      this.$bvModal.hide('fatura-create')
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.custom-select-sm {
  max-width: 80px !important;
}

.perPageText {
  transform: translate(-69px, 0px);
  font-size: 15px;
}

.cardActions .card-body {
  min-height: 550px;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 2%;
  width: 3rem;
  height: 3rem;
}

.mr-12 {
  margin-right: 12px !important;
}

.badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.myButton {
  color: white !important;
  margin-right: 0 !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

fieldset legend {
  transform: translate(29px, -2px) !important;
}

.araButton {
  border-color: #d8d6de !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none !important;
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #d8d6de;
}

.disabledColor {
  border-color: #cbc1eb !important;
  max-height: 39px;
}

.card-headerr .col-8 {
  padding-right: 0 !important;
}

hr {
  border-top: 1px solid #e5e2ef;
}

.alignItemsCenter {
  align-items: center !important;
}

.searchGroup {
  width: 60% !important;
}

@media (max-width: 767.98px) {
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    width: 100% !important;
  }
}

</style>
